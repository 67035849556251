import React from 'react'
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { graphql } from 'gatsby';
import DummyHero from '../../components/dummy-hero';
import Meta from '../../components/meta';
import Layout from '../../components/layout';
import logo from '../../images/favicon.png';
import './index.css';


export const Head = () => {
  // console.log("blogs: ", blogs);
  let meta = {
    title: "About Us",
    description: "About Us Digital Laboratory was founded in 2017 and is being operated by a group of young professionals with expertise and wide exposure in the technology service industry with the purpose of providing technical and business services to its customers utilizing cutting edge cloud technologies. Our Vision To become a bridge between Eastern and Western […]",
    url: "https://www.digitallaboratory.net/company/",
    image: logo
  };

  return (
    <Meta meta={meta} />
  );
};


function Company({ data }) {



  const intro = data.strapiAboutUs.intro.data.intro;

  const mission = data.strapiAboutUs.mission.data.mission;

  const missionImg = {
    image: {
      alt: data.strapiAboutUs.missionImg.alternativeText,
      gatsbyImage: getImage(data.strapiAboutUs.missionImg.localFile)
    }
  }

  const culture = {
    title: data.strapiAboutUs.culture.title,
    body: data.strapiAboutUs.culture.body.data.body,
    image: {
      alt: data.strapiAboutUs.culture.image.alternativeText,
      gatsbyImage: getImage(data.strapiAboutUs.culture.image.localFile)
    }
  }



  return (
    <Layout>
      <DummyHero title="About Us" />
      <div className='aboutWrapper'>

        <div className='aboutWrapper__bgright'>
          <StaticImage
            src="../../images/dllbg_1.png"
            className=""
            quality={100}
            width={400}
            height={400}
            objectFit='cover'
            placeholder="blurred"
            alt="DigitalLaboratory Background Image"
            title="DigitalLaboratory Background Image"
          />
        </div>

        <div className='companyWrapper'>

          <div className="companyWrapper__container" >

            <h2 className='companyWrapper__container__about dl-p2'>
              {intro}
            </h2>

            <div className="companyWrapper__container__mission">
              <div className="companyWrapper__container__mission__text__part">
                <ReactMarkdown>
                  {mission}
                </ReactMarkdown>
              </div>
              <div className="companyWrapper__container__mission__media__part">
                <GatsbyImage
                  width={300}
                  image={missionImg.image.gatsbyImage}
                  alt={missionImg.image.alt}
                  title={missionImg.image.alt}
                />
              </div>
            </div>



            <h3 className='companyWrapper__container__culture__heading '>
              {culture.title}
            </h3>

            <div className="companyWrapper__container__culture">
              <div className="companyWrapper__container__culture__text__part">
                <ReactMarkdown>
                  {culture.body}
                </ReactMarkdown>
              </div>
              <div className="companyWrapper__container__culture__media__part">
                <GatsbyImage
                  image={culture.image.gatsbyImage}
                  alt={culture.image.alt}
                  title={culture.image.alt}
                />
              </div>
            </div>

          </div>

        </div>

        <div className='aboutWrapper__bgleft'>
          <StaticImage
            src="../../images/dllbg_2.png"
            quality={100}
            width={400}
            height={400}
            objectFit='cover'
            placeholder='blurred'
            alt="DigitalLaboratory Background Image"
            title="DigitalLaboratory Background Image"
          />
        </div>

      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    strapiAboutUs {
      intro {
        data {
          intro
        }
      }
      mission {
        data {
          mission
        }
      }
      missionImg {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData( placeholder: TRACED_SVG)
          }
        }
      }
      culture {
        title
        body {
          data {
            body
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData( placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`

export default Company

